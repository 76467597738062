import { Injectable } from '@angular/core';
import { SuntechapiService } from './suntechapi.service';
import { environment } from '../environments/environment';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private gl_division_map = new Map();

  constructor(private suntechApi: SuntechapiService) {
    console.log("shared service call ...");
    this.suntechApi.getDivisionDetails().subscribe((resp) => {
      if (resp.Status == 'Success') {
        console.log(resp.Result.length);
        for (var i = 0; i < resp.Result.length; i++) {
          this.gl_division_map.set(resp.Result[i].DIVISION_CODE, resp.Result[i].DIVISION);
        }
        let jsonObject = {};
        this.gl_division_map.forEach((value, key) => {
          jsonObject[key] = value;
        });

      }
    });
  }

  getDivisions(){
    return this.gl_division_map;
  }
}
